<template>
  <div class="contact-page">
    <LoadingSpinner v-if="loading" />

    <template v-else-if="!exception">
      <PageBanner
        :background="content.fields.simpleContentPage.fields.pageBanner.fields.background"
        :heading="content.fields.simpleContentPage.fields.pageBanner.fields.heading"
        :subheading="content.fields.simpleContentPage.fields.pageBanner.fields.subheading" />
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="contact-page-title">
              Öppen Fibers kundtjänst
            </h3>

            <ContactInformation :contact-items="content.fields.contacts" />

            <RichTextRenderer :document="content.fields.simpleContentPage.fields.content" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PageBanner from '@/components/views/PageBanner'
import LoadingSpinner from '@/components/LoadingSpinner'
import ContactInformation from '@/components/ContactInformation'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'

import {
  mapActions,
  mapState
} from 'vuex'

export default {
  components: {
    ContactInformation,
    LoadingSpinner,
    PageBanner,
    RichTextRenderer
  },
  computed: {
    ...mapState('ContactPage', [
      'loading',
      'exception',
      'content'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('ContactPage', ['loadContent'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.contact-page {
  margin-bottom: 50px;

  &-title {
    margin-top: 50px;
  }
}

</style>
